<template>
  <div class="menu-wrapper">
    <template v-for="item in routes">
      <!-- 把需要隐藏的菜单过滤了 -->
      <template
        v-if="
          !item.hidden &&
          item.children &&
          getState(item.useTypeHidden) &&
          getVersionState(item.versionTypeHidden) &&
          isBdsh(item.bdshHidden) &&
          isClientHidden(item.isClientHidden) &&
          isXhs(item.xhsHidden)
        "
      >
        <!--一级菜单-->
        <router-link
          v-if="
            hasOneShowingChildren(item.children) &&
            !item.children[0].children &&
            getState(item.useTypeHidden) &&
            getVersionState(item.versionTypeHidden)
          "
          :to="item.path + '/' + item.children[0].path"
          :key="item.children[0].name"
        >
          <el-menu-item :index="item.path + '/' + item.children[0].path" style="position: relative">
            <div class="title_item">
<!--              <div class="title_item" :style="isVersion(item) ? 'opacity:0.4' : ''">-->
              <img
                v-if="item.children[0].meta && item.children[0].meta.icon"
                :src="item.children[0].meta.icon"
              />
              <span v-if="item.children[0].meta && item.children[0].meta.title" slot="title">
                {{ item.children[0].meta.title }}
              </span>
            </div>
<!--            <img v-if="isVersion(item)" src="../assets/per.png" class="logosimg" alt="" />-->
          </el-menu-item>
        </router-link>

        <!-- 多级菜单-->
        <el-submenu v-else :index="item.name || item.path" :key="item.name">
          <!-- 显示一级菜单 -->
          <template slot="title">
            <div class="title_item">
              <img
                v-if="item.children[0].meta && item.children[0].meta.icon"
                :src="item.children[0].meta.icon"
              />
              <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
            </div>
          </template>

          <!-- 循环遍历二级菜单-->
          <template v-for="child in item.children">
            <template
              v-if="
                !child.hidden &&
                getState(child.useTypeHidden) &&
                getVersionState(item.versionTypeHidden) &&
                isKS(child.dyHidden)
              "
            >
              <sidebar-item
                :is-nest="true"
                class="nest-menu"
                v-if="child.children && child.children.length > 0"
                :routes="[child]"
                :key="child.path"
              ></sidebar-item>
              <router-link v-else :to="item.path + '/' + child.path" :key="child.name">
                <el-menu-item
                  :index="item.path + '/' + child.path"
                  class="test"
                  style="position: relative"
                >
                  <div class="title_item" :style="isVersion(child) ? 'opacity:0.4' : ''">
                    <span v-if="child.meta && child.meta.title" slot="title">
                      {{ child.meta.title }}
                    </span>
                  </div>
                  <img v-if="isVersion(child)" src="../assets/per.png" class="logosimg" alt="" />
                </el-menu-item>
              </router-link>
            </template>
          </template>
        </el-submenu>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  data() {
    return {
      console: window.console
    }
  },
  props: {
    routes: {
      type: Array
    }
  },
  methods: {
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return !item.hidden
      })
      // 如果长度为一说明没有二级路由
      if (showingChildren.length === 1) {
        return true
      }
      return false
    },
    isVersion(val) {
      if (
        localStorage.getItem('version') == 1 &&
        (val.path == '/productListInfo' ||
          val.path == 'commentAnalysis' ||
          val.path == 'commentDetails' ||
          val.path == 'vocabularyManagement') &&
        localStorage.getItem('isDDorDK') != 5
      ) {
        return true
      }
      return false
    },
    getState(val) {
      const isDDorDK = localStorage.getItem('isDDorDK')
      let state = true
      if (val && isDDorDK != 0) {
        console.log('看看val是什么东西', val)
        state = false
      }
      return state
    },
    isClientHidden(val) {
      const isClient = localStorage.getItem('isClient')
      if (val && isClient == '1') return false
      return true
    },
    // 这个版本先全部放开
    getVersionState() {
      let state = true
      return state
    },
    isKS(val) {
      const isDDorDK = localStorage.getItem('isDDorDK')
      let state = true
      if (val && isDDorDK != 3) {
        state = false
      }
      return state
    },
    isBdsh(val) {
      const isDDorDK = localStorage.getItem('isDDorDK')
      let state = true
      if (val && (isDDorDK == 4 || isDDorDK == 5 || isDDorDK == 6)) {
        state = false
      }
      return state
    },
    isXhs(val) {
      const isDDorDK = localStorage.getItem('isDDorDK')
      let state = true
      if (val && isDDorDK == 7) {
        state = false
      }
      return state
    }
  }
}
</script>

<style lang="less" scoped>
.menu-wrapper a {
  text-decoration: none;
}

.logosimg {
  position: absolute;
  width: 34px;
  height: 15px;
  right: 22px;
  top: 12px;
}

.title_item {
  font-size: 16px;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  span {
    margin-left: 12px;
  }
}

// 重写菜单高度以及字体大小
.el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 12px;
}

// 重写二级菜单标题的高度
/deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 12px;
}

// 重写二级菜单的高度与大小
.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 12px;
  min-width: 170px;
}

// 重写菜单悬浮的样式
.el-menu-item:hover {
  //背景色0.12的透明度
  background: #6647ff1f;
  border-radius: 4px;
}

// 重写菜单选中的样式
.el-menu-item.is-active {
  //背景色0.12的透明度
  background: #6647ff1f;
  border-radius: 4px;
}

// 重写二级菜单的高度与大小
/deep/ .el-submenu__title:hover {
  background: #6647ff1f;
  border-radius: 4px;
}
</style>
